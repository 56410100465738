<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-input
        v-model="key"
        placeholder="请输入订单号/用户ID"
        style="width: 300px"
      ></el-input>
      <el-date-picker
        v-model="value"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="handleDateRangeChange"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="getOrderLists()"
        >搜索</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="日期" width="50"> </el-table-column>
      <el-table-column prop="user_id" label="下单用户ID" width="100">
      </el-table-column>
      <el-table-column prop="order_no" label="订单号"> </el-table-column>
      <el-table-column prop="amount" label="消费金额">
        <template slot-scope="scope">
          <span>{{ scope.row.amount }} CNY</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="订单标题"> </el-table-column>
      <el-table-column prop="status" label="订单状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="warning">待付款</el-tag>
          <el-tag v-if="scope.row.status == 1" type="success">已付款</el-tag>
          <el-tag v-if="scope.row.status == 2" type="danger">退款中</el-tag>
          <el-tag v-if="scope.row.status == 3" type="info">已退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="订单创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getOrderList } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1, // 当前页码
      pageSize: 10, // 每页条数
      total: 0, // 总记录数
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      key: "",
      startTime: "",
      endTime: "",
      value: "",
    };
  },
  methods: {
    handleDateRangeChange(value) {
      if (value && value.length === 2) {
        // 将时间范围分别赋值为 Unix 时间戳
        this.startTime = moment(value[0]).unix();
        this.endTime = moment(value[1]).unix();
        console.log("Start Time:", this.startTime);
        console.log("End Time:", this.endTime);
      } else {
        // 如果未选择时间范围，清空值
        this.startTime = null;
        this.endTime = null;
      }
    },
    handlePageChange(page) {
      this.page = page;
      this.getOrderLists(); // 请求新页面的数据
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getOrderLists() {
      this.loading = true;
      getOrderList(
        this.page,
        this.pageSize,
        this.key,
        this.startTime,
        this.endTime
      ).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("数据获取失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getOrderLists();
  },
};
</script>

<style></style>
