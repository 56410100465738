import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jisuqipai.online/",
});

// 登录接口
export const Login = (username, password) => {
  return axiosInstance.post("/api/admin/login", {
    username: username,
    password: password,
  });
};

// 登录接口
export const getEmailByUsername = (token, username) => {
  return axiosInstance.post(
    "/api/admin/getEmailByUsername",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 验证码发送接口
export const send = (email, event) => {
  return axiosInstance.post("/api/ems/sendTest", {
    email: email,
    event: event,
  });
};

// 绑定邮箱
export const bindEmail = (username, email, captcha) => {
  return axiosInstance.post("/api/admin/bindEmail", {
    username: username,
    email: email,
    captcha: captcha,
  });
};

// 邮箱登录验证接口
export const emailLogin = (username, email, captcha) => {
  return axiosInstance.post("/api/admin/emailLogin", {
    username: username,
    email: email,
    captcha: captcha,
  });
};

// 修改密码
export const changePwd = (token, oldPassword, newPassword) => {
  return axiosInstance.post("/api/admin/changePwd", {
    token: token,
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
};

// 获取用户数据
export const getUsers = (page, pageSize, email, startTime, endTime) => {
  return axiosInstance.post("/api/admin/getUsers", {
    page: page,
    pageSize: pageSize,
    email: email,
    startTime: startTime,
    endTime: endTime,
  });
};

// 对用户更改账户状态
export const changeUserStatus = (token, uid, status) => {
  return axiosInstance.post("/api/admin/changeUserStatus", {
    token: token,
    uid: uid,
    status: status,
  });
};

// 获取代理数据
export const getAgents = (page, pageSize, email, startTime, endTime) => {
  return axiosInstance.post("/api/admin/getAgents", {
    page: page,
    pageSize: pageSize,
    email: email,
    startTime: startTime,
    endTime: endTime,
  });
};

// 根据uid获取下级代理
export const getSubordinateAgents = (token, uid) => {
  return axiosInstance.post("/api/admin/getSubordinateAgents", {
    token: token,
    uid: uid,
  });
};

// 获取极速学院视频列表
export const getSchoolVideos = (page, pageSize) => {
  return axiosInstance.post("/api/admin/getSchoolVideos", {
    page: page,
    pageSize: pageSize,
  });
};

// 通过id查看极速学院子视频id
export const getSchoolVideoById = (id) => {
  return axiosInstance.post("/api/admin/getSchoolVideoById", {
    id: id,
  });
};

// 上传极速学院子视频
export const uploadSchoolVideo = (token, id, title, file) => {
  return axiosInstance.post("/api/admin/uploadSchoolVideo", {
    token: token,
    id: id,
    title: title,
    file: file,
  });
};

// 编辑极速学院子视频
export const editSchoolVideo = (token, id, title, file) => {
  return axiosInstance.post("/api/admin/editSchoolVideo", {
    token: token,
    id: id,
    title: title,
    file: file,
  });
};

// 删除视频选集
export const delvideos = (token, id) => {
  return axiosInstance.post("/api/admin/delvideos", {
    token: token,
    id: id,
  });
};

// 获取极速学院视频分类
export const getVideoType = () => {
  return axiosInstance.post("/api/admin/getVideoType");
};

// 添加极速学院视频
export const addvideo = (token, title, tag, img, price, type) => {
  return axiosInstance.post("/api/admin/addvideo", {
    token: token,
    title: title,
    tag: tag,
    img: img,
    price: price,
    type: type,
  });
};

// 删除极速学院视频
export const delvideo = (token, id) => {
  return axiosInstance.post("/api/admin/delvideo", {
    token: token,
    id: id,
  });
};

// 获取活动列表
export const getActivityList = () => {
  return axiosInstance.post("/api/admin/getActivityList", {});
};

// 图片上传
export const uploadImg = (img, config) => {
  return axiosInstance.post(
    "/api/user/uploadImg",
    {
      img: img,
    },
    config
  );
};

// 添加活动
export const addActivity = (
  title,
  description,
  jump_url,
  priority,
  image_url,
  start_time,
  end_time
) => {
  return axiosInstance.post("/api/admin/addActivity", {
    title: title,
    description: description,
    jump_url: jump_url,
    priority: priority,
    image_url: image_url,
    start_time: start_time,
    end_time: end_time,
  });
};

// 删除活动
export const delActivity = (id) => {
  return axiosInstance.post("/api/admin/delActivity", {
    id: id,
  });
};

// 修改活动
export const editActivity = (
  id,
  title,
  description,
  jump_url,
  priority,
  image_url,
  start_time,
  end_time,
  is_show
) => {
  return axiosInstance.post("/api/admin/editActivity", {
    id: id,
    title: title,
    description: description,
    jump_url: jump_url,
    priority: priority,
    image_url: image_url,
    start_time: start_time,
    end_time: end_time,
    is_show: is_show,
  });
};

// 获取轮播图
export const getBanner = () => {
  return axiosInstance.post("/api/admin/getBanner", {});
};

// 新增轮播图
export const addBanner = (priority, is_show, image_url) => {
  return axiosInstance.post("/api/admin/addBanner", {
    priority: priority,
    is_show: is_show,
    image_url: image_url,
  });
};

// 删除轮播图
export const delBanner = (id) => {
  return axiosInstance.post("/api/admin/delBanner", {
    id: id,
  });
};

// 编辑轮播图
export const editBanner = (id, priority, is_show, image_url) => {
  return axiosInstance.post("/api/admin/editBanner", {
    id: id,
    priority: priority,
    is_show: is_show,
    image_url: image_url,
  });
};

// 获取新闻列表
export const getNewsList = () => {
  return axiosInstance.post("/api/admin/getNewsList", {});
};

// 添加新闻
export const addNews = (title, image, content) => {
  return axiosInstance.post("/api/admin/addNews", {
    title: title,
    image: image,
    content: content,
  });
};

// 编辑新闻
export const editNews = (id, title, image, content) => {
  return axiosInstance.post("/api/admin/editNews", {
    id: id,
    title: title,
    image: image,
    content: content,
  });
};

// 删除新闻
export const deleteNews = (id) => {
  return axiosInstance.post("/api/admin/deleteNews", {
    id: id,
  });
};

// 获取总览数据
export const dashboard = () => {
  return axiosInstance.post("/api/admin/dashboard", {});
};

// 获取订单列表
export const getOrderList = (page, pageSize, key, startTime, endTime) => {
  return axiosInstance.post("/api/admin/getOrderList", {
    page: page,
    pageSize: pageSize,
    key: key,
    startTime: startTime,
    endTime: endTime,
  });
};

// 获取服务器分类列表
export const getServerCategoryList = () => {
  return axiosInstance.post("/api/admin/getServerCategoryList", {});
};

// 添加服务器分类
export const addServerCategory = (title, priority, image) => {
  return axiosInstance.post("/api/admin/addServerCategory", {
    title: title,
    priority: priority,
    image: image,
  });
};

// 修改服务器分类
export const updateServerCategory = (id, title, priority, image) => {
  return axiosInstance.post("/api/admin/updateServerCategory", {
    id: id,
    title: title,
    priority: priority,
    image: image,
  });
};

// 删除服务器分类
export const deleteServerCategory = (id) => {
  return axiosInstance.post("/api/admin/deleteServerCategory", {
    id: id,
  });
};

// 根据id获取服务器列表
export const getServerList = (id) => {
  return axiosInstance.post("/api/admin/getServerList", {
    id: id,
  });
};

// 修改服务器
export const updateServer = (
  id,
  region,
  left_title,
  left_desc,
  cpu_cores,
  ram_size,
  broadband,
  disk_size,
  month_mark_price,
  month_real_price,
  year_mark_price,
  year_real_price,
  op_system,
  priority,
  category
) => {
  return axiosInstance.post("/api/admin/updateServer", {
    id: id,
    region: region,
    left_title: left_title,
    left_desc: left_desc,
    cpu_cores: cpu_cores,
    ram_size: ram_size,
    broadband: broadband,
    disk_size: disk_size,
    month_mark_price: month_mark_price,
    month_real_price: month_real_price,
    year_mark_price: year_mark_price,
    year_real_price: year_real_price,
    op_system: op_system,
    priority: priority,
    category: category,
  });
};

// 添加服务器
export const addServer = (
  region,
  left_title,
  left_desc,
  cpu_cores,
  ram_size,
  broadband,
  disk_size,
  month_mark_price,
  month_real_price,
  year_mark_price,
  year_real_price,
  op_system,
  priority,
  category
) => {
  return axiosInstance.post("/api/admin/addServer", {
    region: region,
    left_title: left_title,
    left_desc: left_desc,
    cpu_cores: cpu_cores,
    ram_size: ram_size,
    broadband: broadband,
    disk_size: disk_size,
    month_mark_price: month_mark_price,
    month_real_price: month_real_price,
    year_mark_price: year_mark_price,
    year_real_price: year_real_price,
    op_system: op_system,
    priority: priority,
    category: category,
  });
};

// 删除服务器
export const delServer = (id) => {
  return axiosInstance.post("/api/admin/delServer", {
    id: id,
  });
};

// 获取产品信息
export const getProduct = () => {
  return axiosInstance.post("/api/admin/getProduct", {});
};

// 添加产品
export const addProduct = (
  title,
  description,
  image_url,
  jump_url,
  priority
) => {
  return axiosInstance.post("/api/admin/addProduct", {
    title: title,
    description: description,
    image_url: image_url,
    jump_url: jump_url,
    priority: priority,
  });
};

// 删除服务器
export const deleteProduct = (id) => {
  return axiosInstance.post("/api/admin/deleteProduct", {
    id: id,
  });
};

// 修改产品
export const updateProduct = (
  id,
  title,
  description,
  image_url,
  jump_url,
  priority
) => {
  return axiosInstance.post("/api/admin/updateProduct", {
    id: id,
    title: title,
    description: description,
    image_url: image_url,
    jump_url: jump_url,
    priority: priority,
  });
};

// 获取服务管理列表
export const getServeList = (page, list,need) => {
  return axiosInstance.post("/api/admin/getServeList", {
    page: page,
    list: list,
    need: need,
  });
};

// 删除服务
export const deleteService = (id) => {
  return axiosInstance.post("/api/admin/deleteService", {
    id: id,
  });
};
// 修改服务
export const updateService = (
  id,
  game,
  type,
  contact,
  info,
  tec,
  need,
  img
) => {
  return axiosInstance.post("/api/admin/updateService", {
    id: id,
    game: game,
    type: type,
    contact: contact,
    info: info,
    tec: tec,
    need: need,
    img: img,
  });
};
