<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="primary" @click="dialogVisible = true"
        >+新增活动</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="image_url" label="图片" width="160">
        <template slot-scope="scope">
          <el-image
            style="width: 150px; height: 70px"
            :src="scope.row.image_url"
            :preview-src-list="[scope.row.image_url]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="活动标题"> </el-table-column>
      <el-table-column prop="description" label="活动描述"> </el-table-column>
      <el-table-column prop="jump_url" label="转跳到URL"> </el-table-column>
      <el-table-column prop="priority" label="优先级"> </el-table-column>
      <el-table-column prop="is_show" label="是否显示">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show == 1" type="success">显示</el-tag>
          <el-tag v-else type="danger">隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="活动开始时间">
        <template slot-scope="scope">
          <span v-if="scope.row.start_time">{{
            formatDate(scope.row.start_time)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="end_time" label="活动结束时间">
        <template slot-scope="scope">
          <span v-if="scope.row.end_time">{{
            formatDate(scope.row.end_time)
          }}</span>
          <span v-else> 长期活动 </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.create_time">{{
            formatDate(scope.row.create_time)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="最后编辑时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">{{
            formatDate(scope.row.update_time)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delActivitys(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增活动弹窗 -->
    <el-dialog title="新增活动" :visible.sync="dialogVisible" width="80%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form label-width="80px">
            <el-form-item label="活动标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="活动描述">
              <el-input v-model="form.description"></el-input>
            </el-form-item>
            <el-form-item label="转跳URL">
              <el-input v-model="form.jump_url">
                <template slot="prepend">Http://</template>
              </el-input>
            </el-form-item>
            <el-form-item label="优先级">
              <el-input v-model="form.priority"></el-input>
            </el-form-item>
            <el-form-item label="活动图片">
              <el-upload
                class="avatar-uploader"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
                v-loading="img_loading"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form label-width="120px">
            <el-form-item label="活动开始时间">
              <el-date-picker
                v-model="form.start_time"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="活动结束时间">
              <el-date-picker
                v-model="form.end_time"
                align="right"
                type="date"
                placeholder="选择日期(此处留空则为长期活动)"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addActivitys()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑活动弹窗 -->
    <el-dialog title="编辑活动" :visible.sync="editDialogVisible" width="80%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form label-width="80px">
            <el-form-item label="活动标题">
              <el-input v-model="edit.title"></el-input>
            </el-form-item>
            <el-form-item label="活动描述">
              <el-input v-model="edit.description"></el-input>
            </el-form-item>
            <el-form-item label="转跳URL">
              <el-input v-model="edit.jump_url">
                <template slot="prepend">Http://</template>
              </el-input>
            </el-form-item>
            <el-form-item label="优先级">
              <el-input v-model="edit.priority"></el-input>
            </el-form-item>
            <el-form-item label="活动图片">
              <el-upload
                class="avatar-uploader"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
                v-loading="img_loading"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form label-width="120px">
            <el-form-item label="活动开始时间">
              <el-date-picker
                v-model="edit.start_time"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="活动结束时间">
              <el-date-picker
                v-model="edit.end_time"
                align="right"
                type="date"
                placeholder="选择日期(此处留空则为长期活动)"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否显示">
              <el-radio-group v-model="edit.is_show">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="0">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editActivitys()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addActivity,
  delActivity,
  editActivity,
  getActivityList,
  uploadImg,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      tableData: [],
      loading: false,
      dialogVisible: false,
      form: [],
      img_loading: false,
      imageUrl: "",
      progress: "",
      editDialogVisible: false,
      edit: [],
    };
  },
  methods: {
    // 修改活动
    editActivitys() {
      if (
        (this.edit.id,
        this.edit.title,
        this.edit.description,
        this.edit.jump_url,
        this.edit.priority,
        this.imageUrl,
        this.edit.start_time,
        this.edit.end_time)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在修改",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        editActivity(
          this.edit.id,
          this.edit.title,
          this.edit.description,
          this.edit.jump_url,
          this.edit.priority,
          this.imageUrl,
          this.edit.start_time,
          this.edit.end_time,
          this.edit.is_show
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.getActivityLists();
            this.editDialogVisible = false;
            loading.close();
          } else {
            this.$message.error("修改失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    openEditDialog(row) {
      this.edit = { ...row };
      this.imageUrl = row.image_url;
      this.edit.start_time = moment.unix(row.start_time).format("YYYY-MM-DD");
      if (row.end_time) {
        this.edit.end_time = moment.unix(row.end_time).format("YYYY-MM-DD");
      }
      this.editDialogVisible = true;
    },
    delActivitys(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      delActivity(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getActivityLists();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    // 添加活动
    addActivitys() {
      if (
        (this.form.title,
        this.form.description,
        this.form.jump_url,
        this.form.priority,
        this.imageUrl,
        this.form.start_time,
        this.form.end_time)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addActivity(
          this.form.title,
          this.form.description,
          this.form.jump_url,
          this.form.priority,
          this.imageUrl,
          this.form.start_time,
          this.form.end_time
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.getActivityLists();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("添加失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
    getActivityLists() {
      this.loading = true;
      getActivityList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("获取活动失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getActivityLists();
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
